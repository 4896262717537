import dashboardBlog from "../img/blogs/charles-dashboard.png";
import virus_tracker from "../img/blogs/virus_tracker.png";
import expenseviewerBlog from "../img/blogs/expense-viewer.png";
import twaboriginalBlog from "../img/blogs/twaboriginalBlog.png";

const blogs = [
  {
    id: 1,
    image: dashboardBlog,
    title: "Charles Dashboard",
    link: "https://charles-dashboard.netlify.app/",
    date: "01",
    month: "Feb",
    year: "2021",
  },
  {
    id: 2,
    image: virus_tracker,
    title: "Conora Virus Tracker",
    link: "https://virustracker.netlify.app/",
    date: "06",
    month: "Mar",
    year: "2020",
  },
  {
    id: 3,
    image: expenseviewerBlog,
    title: "Expense Viewer",
    link: "https://expense-viewer-2020.netlify.app/",
    date: "15",
    month: "Jun",
    year: "2018",
  },
  {
    id: 4,
    image: twaboriginalBlog,
    title: "Taiwan Aboriginal Information",
    link: "https://tw-aboriginal-festival.herokuapp.com/",
    date: "15",
    month: "Mar",
    year: "2018",
  },
];

export default blogs;
