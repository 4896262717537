// import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
// import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <div className="HomePage">
      <header className="hero">
        <h1 className="hero-text">
          Hi, My name is
          <span> Charles Sin C.Y.</span>
        </h1>
        <p className="h-sub-text">
          I make web application using React, Vue.I make backend server using
          Node js.
        </p>
        <p className="h-sub-text">
          Check out my latest work on the{" "}
          <Link to="/portfolios">Portfolio Page.</Link>
        </p>
        <div className="icons">
          <a href="https://github.com/D0561910" className="icon-holder">
            <FontAwesomeIcon icon={faGithub} className="icon gh" />
          </a>
        </div>
      </header>
    </div>
  );
};

export default HomePage;
