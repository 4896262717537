import port3 from "../img/portImages/port2.jpg";
import port4 from "../img/portImages/port3.jpg";
import port6 from "../img/portImages/port5.jpg";
import twaboriginalBlog from "../img/blogs/twaboriginalBlog.png";
import expenseTracker from "../img/portImages/expense-tracker.png";
import todolist from "../img/portImages/todolist.png";

const portfolios = [
  {
    id: 1,
    category: "React",
    link1: "https://github.com/D0561910/ExpenseTracker_with_Voice",
    icon1: "Github",
    image: expenseTracker,
    title: "ExpenseTracker Voice",
  },
  {
    id: 2,
    category: "React",
    link1: "https://github.com/D0561910/react_todolist",
    icon1: "Github",
    image: todolist,
    title: "React Todolist",
  },
  {
    id: 3,
    category: "Node",
    link1: "https://github.com/D0561910/Expense_Tracker_Backend",
    icon1: "Github",
    image: port3,
    title: "Node js Backend server",
  },
  {
    id: 4,
    category: "Vue",
    link1: "https://github.com/D0561910/MEVN_Stack",
    icon1: "Github",
    image: port4,
    title: "MEVN Stack",
  },
  {
    id: 5,
    category: "Unit Test",
    link1: "https://github.com/D0561910/AMW_Server",
    icon1: "Github",
    image: port4,
    title: "AMW Website",
  },
  {
    id: 6,
    category: "React Native",
    link1: "https://github.com/D0561910/RN_Task_App",
    icon1: "Github",
    image: port6,
    title: "Task Mobile Apps",
  },
  {
    id: 7,
    category: "Vanilla Js",
    link1: "https://github.com/D0561910/Web_Course_Final_Project",
    icon1: "Github",
    image: twaboriginalBlog,
    title: "Vanilla Javascript Website",
  },
];

export default portfolios;
